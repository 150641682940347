import { FgBaseAPI } from './base-api';

export class FgStorageKdbAPI extends FgBaseAPI {
  dbCredentials: any;
  decodedCredentials: any;

  constructor(param) {
    super(param);
    this.dbCredentials = param.dbCredentials;
    this.decodedCredentials = param.decodedCredentials;
  }

  get databaseUuid() {
    return this.dbCredentials.databaseUuid;
  }

  get connectionId() {
    return this.decodedCredentials.id;
  }

  get projectId() {
    return this.decodedCredentials.projectId;
  }

  protected _afterLoad() {
    this.baseUrl += 'api/storage/kevin/';
  }

  async connectWithIdentity(storage, dbUuid) {
    const result = await storage.connect(dbUuid, 'kevin', '$identity', null);
    return result;
  }

  async disconnect() {
    await this._delete(`${this.databaseUuid}`);
  }

  async getValue(path) {
    const response = await this._post(`${this.databaseUuid}/get`, { path });
    return response.value;
  }

  async find(path) {
    const response = await this._post(`${this.databaseUuid}/find`, { path });
    return response.values;
  }

  async findKeys(path) {
    const response = await this._post(`${this.databaseUuid}/findKeys`, { path });
    return response.routes;
  }

  async findOne(path) {
    const response = await this._post(`${this.databaseUuid}/findOne`, { path });
    return response.tuple;
  }

  async addValue(path, value) {
    await this._post(`${this.databaseUuid}/add`, { path, value });
  }

  async setValue(path, value) {
    await this._post(`${this.databaseUuid}/set`, { path, value });
  }

  async move(path, newPath) {
    await this._post(`${this.databaseUuid}/move`, { path, newPath });
  }

  async rename(path, newName) {
    await this._post(`${this.databaseUuid}/ren`, { path, newName });
  }

  async exists(path) {
    const response = await this._post(`${this.databaseUuid}/exists`, { path });
    return response.exists;
  }

  async deleteValue(path) {
    const response = await this._post(`${this.databaseUuid}/del`, { path });
    return response.deleted;
  }

  protected _createHeaders(extraHeaders) {
    const headers = super._createHeaders(extraHeaders);

    if (this.dbCredentials) {
      headers['x-database-session'] = this.dbCredentials.token;
    }

    return headers;
  }
}
