import { ILogRawEntry } from '../interfaces/dto/log-entry-dto.interface';
import { ILoggerNewSessionDTO } from '../interfaces/dto/logger-new-session-dto.interface';
import { IMetricListDTO } from '../interfaces/dto/metric-list-dto.interface';
import { INewSessionArgsDTO } from '../interfaces/dto/new-session-args-dto.interface';
import { ILogEntry } from '../interfaces/log-entry.interface';
import { Base64Utils } from '../utils/base64-utils';
import { FgBaseAPI } from './base-api';

export class FgLoggerAPI extends FgBaseAPI {
  protected _afterLoad() {
    this.baseUrl += 'api/logger/';
  }

  createSession(optionalData?: INewSessionArgsDTO): Promise<ILoggerNewSessionDTO> {
    return this._post('session', optionalData || {});
  }

  addRawEntry(sessionId: number, token: string, rawEntry: ILogEntry | ILogRawEntry): Promise<void> {
    const entry = Object.assign({}, rawEntry, { sessionId }) as ILogRawEntry;
    const headers = this._getSessionExtraHeaders(sessionId, token);
    return this._post('entry', entry, headers);
  }

  addEntry(sessionId: number, token: string, type, message: string, category?: string, date?: Date, tags?: string[]): Promise<void> {
    const headers = this._getSessionExtraHeaders(sessionId, token);
    return this._post('entry', {
      sessionId,
      type,
      message,
      category,
      date: date || new Date(),
      tags,
    }, headers);
  }

  closeSession(sessionId: number, token: string): Promise<void> {
    const headers = this._getSessionExtraHeaders(sessionId, token);
    return this._delete('session', undefined, headers);
  }

  getMetrics(): Promise<IMetricListDTO> {
    return this._get('metric');
  }

  addValueToMetric(identifier: string, value: number, valueTag?: string, valueIdentifier?: string): Promise<void> {
    return this._post(`metric/${identifier}`, { value, tag: valueTag, identifier: valueIdentifier, operation: '+' });
  }

  setMetricValue(identifier: string, value: number, valueTag?: string, valueIdentifier?: string): Promise<void> {
    return this._post(`metric/${identifier}`, { value, tag: valueTag, identifier: valueIdentifier, operation: '=' });
  }

  private _getSessionExtraHeaders(logId: number, token: string) {
    if (!token) {
      return null;
    }

    const finalToken = Base64Utils.stringToBase64(`${logId}:${token}`);
    const headers: Record<string, string> = {
      'x-logger-session': finalToken
    };

    return headers;
  }
}