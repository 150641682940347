import { Nexus } from '../fg-api';
import { BrowserStorage } from '../storages';
import { BaseSessionManager } from './base-session-manager';

export class IdentitySessionManager extends BaseSessionManager {
  cache: boolean;
  fgApi: Nexus;
  credentials: any;
  sessionId: string;

  constructor(api: Nexus) {
    super(new BrowserStorage());

    if (!api) {
      throw new Error('FG Api is required');
    }

    this.fgApi = api;
    this.cache = true;
    this.credentials = null;
    this.sessionId = `s-${Date.now()}`;
  }

  get fileName() {
    return 'identity';
  }

  get api() {
    const apiInstance = this.fgApi.getIdentity();
    apiInstance.cache = this.cache;
    return apiInstance;
  }

  get isLoggedIn() {
    return !!this.credentials;
  }

  get user() {
    return this.credentials?.user;
  }

  async openLoginWindow() {
    const response = await this.api.openLoginWindow();
    if (response) {
      this.setCredentials(response);

      // return true;
      //  TODO  Patch - The first login session is not working for retrieving account details. Fix me please.
      return await this.tryToRecoverSession();
    }

    return false;
  }

  async openProfileWindow(id) {
    return await this.api.openViewProfileWindow(id || this.user?.id);
  }

  async openRemoveAccountWindow() {
    return await this.api.openRemoveAccountWindow();
  }

  async openChangePasswordWindow() {
    return await this.api.openChangePasswordWindow();
  }

  async tryToRecoverSession() {
    try {
      if (this.loadCredentials()) {
        const newCredentials = await this.api.tryToRelogin(this.credentials.token, this.credentials.reloginToken, this.sessionId);
        this.setCredentials(newCredentials);
        return true;
      }
    }
    catch (err) {
      console.error(err);
      return false;
    }

    return false;
  }

  loadCredentials() {
    this.credentials = this._getContent();

    if (this.credentials === '') {
      this.credentials = null;
    }

    if (typeof this.credentials === 'string') {
      this.credentials = JSON.parse(this.credentials);
    }

    return !!this.credentials;
  }

  async logout(): Promise<void> {
    try {
      await this.api.logout();
    }
    catch (err) {
      console.error(err);
    }
    finally {
      this.clearCredentials();
    }
  }

  setCredentials(credentials) {
    this.credentials = credentials;
    this.saveCredentials();
  }

  saveCredentials() {
    if (this.credentials) {
      this.saveContent(JSON.stringify(this.credentials));
    }
  }

  clearCredentials() {
    this.deleteContent();
  }
}
