import { EnvironmentType } from './environment.type';

const urls = {
  development: 'http://localhost:3001',
  production: 'https://api.forjagames.com',
  rapidapi: 'https://forja-games.p.rapidapi.com',
};

export interface IConstants {
  urls: Record<EnvironmentType, string>;
}

export const constants: IConstants = { urls };
