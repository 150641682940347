// eslint-disable-next-line no-var
declare var window: any;

export class Base64Utils {
  static stringToBase64(text: string): string {
    if (window && window.btoa) {
      return window.btoa(text);
    }

    return Buffer.from(text).toString('base64');
  }

  static base64ToString(text: string): string {
    if (window && window.atob) {
      return window.atob(text);
    }

    return Buffer.from(text, 'base64').toString('utf8');
  }
}