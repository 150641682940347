import { constants } from '../../enum/constants';
import { IEncryptionDriver } from '../../interfaces';
import { ICredentialObject } from '../../interfaces/credential-object.interface';
import { IRequestDriver } from '../../interfaces/request-driver.interface';
import { BaseAPIConstructorOpts } from './base-api-constructor-opts';

const { urls } = constants;

export class BaseAPIConstructorParameters {
  requestDriver: IRequestDriver;
  encryptionDriver: IEncryptionDriver;
  opts: BaseAPIConstructorOpts;
  credentialObject: ICredentialObject | null;
  baseURL: string;

  constructor(driver: IRequestDriver, encryptionDriver: IEncryptionDriver, baseUrl?: string, opts?: BaseAPIConstructorOpts, credentialObject?: ICredentialObject) {
    if (driver == null) {
      throw new Error('RequestDriver is required');
    }

    if (encryptionDriver == null) {
      throw new Error('EncryptionDriver is required');
    }

    this.requestDriver = driver;
    this.encryptionDriver = encryptionDriver;
    this.opts = opts ?? new BaseAPIConstructorOpts();
    this.baseURL = baseUrl ?? urls[this.opts.environment];
    this.credentialObject = credentialObject || null;
  }

  get rapidAPIKey() {
    if (this.environment == 'rapidapi') {
      return this.opts.keys['rapidapi'] || null;
    }

    return null;
  }

  get isProduction() {
    return this.opts.isProduction
  }

  get environment() {
    return this.opts.environment;
  }

  get onLogin() {
    return this.credentialObject?.onLogin ?? this.opts.onLogin
  }
}
