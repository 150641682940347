import { FgBaseAPI } from './base-api';

export class FgFunctionsAPI extends FgBaseAPI {
  protected _afterLoad() {
    this.baseUrl += 'api/functions/';
  }

  runRecipe(instance: string, data: any) {
    return this._post(`recipe/run/${instance}`, data ? { ...data } : {});
  }
}