import * as CryptoJS from 'crypto-js';
import { IEncryptionDriver } from '../interfaces/encryption-driver.interface';

export class EncryptionManager implements IEncryptionDriver {
  encrypt(text: string, password: string): string {
    return CryptoJS.AES.encrypt(text, password).toString();
  }

  decrypt(text: string, password: string): string {
    const bytes = CryptoJS.AES.decrypt(text, password);
    const result = bytes.toString(CryptoJS.enc.Utf8);
    return result;
  }

  getSha256HashBase64(text: string): string {
    const bytes = CryptoJS.SHA256(text);
    const result = bytes.toString(CryptoJS.enc.Base64);
    return result;
  }
}