import { ApiResponseType } from '../enum/response-type.type';
import { IRequestDriver } from '../interfaces/request-driver.interface';

export class AxiosRequestDriver implements IRequestDriver {
  constructor(private axios: any) {
    if (!axios) {
      throw new Error('Axios library is missing.');
    }
  }

  async send(method: string, baseUrl: string, url: string, data: any, usesAuth: boolean, headers: Record<string, string>, responseType?: ApiResponseType) {
    const request: any = {
      baseURL: baseUrl,
      url,
      method,
      withCredentials: usesAuth,
      headers,
    };

    if (responseType === 'blob') {
      request.responseType = 'blob';
    }

    if (typeof data !== 'undefined' && method !== 'GET') {
      request.data = data;
    }

    const response = await this.axios(request);

    return response;
  }

  async upload(method: string, baseUrl: string, url: string, data: string, projectId: string, content: string | Blob, fileName: string, headers: Record<string, string>): Promise<any> {
    const formData = new FormData();
    formData.set('projectId', projectId || '');
    formData.set('data', data || '');

    if (typeof content === 'string') {
      content = new Blob([content], { type: 'plain/text' });
    }

    formData.set('files', content, fileName);

    const request = {
      baseURL: baseUrl,
      data: formData,
      url: url,
      method,
      withCredentials: true,
      headers,
    };

    const response = await this.axios(request);

    return response;
  }

  async getContentAsBlob(response) {
    //  TODO  Add to the request: responseType: 'blob',

    if (response.status === 204) {
      //  No content
      return undefined;
    }

    return response.data;
  }

  async getContentAsText(response) {
    if (response.status === 204) {
      //  No content
      return undefined;
    }

    return response.data;
  }

  async getBody(response) {
    if (response.status === 204) {
      //  No content
      return undefined;
    }

    return response.data;
  }

  getHeaders(response: any): Record<string, string> {
    return response.headers;
  }
}
