export class AsyncHelper {
  static sleep(ms: number) {
    return new Promise<void>(resolve => {
      setTimeout(() => resolve(), ms);
    });
  }

  /**
   * @param {Function<boolean>} condition Condition
   * @param {number} [sleepTime=100] Sleep time between checks
   * @param {number} [timeOut] Timeout
   */
  static async waitFor(condition: Function, sleepTime = 100, timeOut = 0) {
    timeOut = timeOut || 0;
    sleepTime = sleepTime || 100;

    const hasTimeout = timeOut > 0;

    while (!condition() && (!hasTimeout || timeOut > 0)) {
      await AsyncHelper.sleep(sleepTime);
      timeOut -= sleepTime;
    }

    if (hasTimeout && timeOut <= 0) {
      throw new Error('Timeout');
    }
  }
}
