import { IEncryptionDriver } from '../interfaces/encryption-driver.interface';
import { IStorage } from '../interfaces/storage.interface';
import { MemoryStorage } from '../storages';
import { EncryptionManager } from './encryption-manager';

export abstract class BaseSessionManager {
  private _storage: IStorage;
  private _encryption: IEncryptionDriver;

  constructor(_storage?: IStorage, _encryption?: IEncryptionDriver) {
    this._storage = _storage || new MemoryStorage();
    this._encryption = _encryption || new EncryptionManager();
  }

  get fileName() {
    return 'token';
  }
  
  get serialNumber() {
    return 'no-serial-number';
  }

  protected get encryptionManager() {
    return this._encryption;
  }

  protected _getContent() {
    const content = this._storage.read(this.fileName);
    if (!content) {
      return null;
    }

    try {
      const decContent = this.encryptionManager.decrypt(content, this.serialNumber);
      return decContent;
    }
    catch (err) {
      console.error(err);
      console.warn('Decryption failed. Deleting file...');
      this._storage.delete(this.fileName);
    }

    return null;
  }

  saveContent(content) {
    const encToken = this.encryptionManager.encrypt(content, this.serialNumber);
    this._storage.save(this.fileName, encToken);
  }

  deleteContent() {
    this._storage.save(this.fileName, '');
  }
}
