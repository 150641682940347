import { FgBaseAPI } from './base-api';

export class FgCredentialAPI extends FgBaseAPI {
  protected _afterLoad() {
    this.baseUrl += 'api/credential/';
  }

  isWorking() {
    return this._post('status', {});
  }
}
