import { ApiResponseType } from '../enum/response-type.type';
import { IRequestDriver } from '../interfaces/request-driver.interface';

export class FetchRequestDriver implements IRequestDriver {
  async send(method: string, baseUrl: string, url: string, data: any, usesAuth: boolean, headers: Record<string, string>, responseType?: ApiResponseType) {
    if (method !== 'GET' && typeof data !== 'string') {
      data = JSON.stringify(data);

      headers = Object.assign({}, headers, {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
    }

    const request = new Request(baseUrl + url, {
      method,
      body: data,
      headers: new Headers(headers),
      credentials: usesAuth ? 'same-origin' : 'omit',
      cache: 'no-cache'
    });

    const response = await fetch(request);
    const finalResponse = await this.processFetchResponse(response);

    return finalResponse;
  }

  async upload(method: string, baseUrl: string, url: string, data: string, projectId: string, content: string | Blob, fileName: string, headers: Record<string, string>): Promise<any> {
    const formData = new FormData();
    formData.set('projectId', projectId || '');
    formData.set('data', data || '');

    if (typeof content === 'string') {
      content = new Blob([content], { type: 'plain/text' });
    }

    formData.set('files', content, fileName);

    const usesAuth = true;

    const request = new Request(baseUrl + url, {
      method,
      body: formData,
      headers: new Headers(headers),
      credentials: usesAuth ? 'same-origin' : 'omit',
      cache: 'no-cache'
    });

    const response = await fetch(request);
    const finalResponse = await this.processFetchResponse(response);

    return finalResponse;
  }

  async getContentAsBlob(response) {
    const fetchResponse: Response = response;
    if (fetchResponse.status === 204) {
      //  No content
      return undefined;
    }

    return await response.blob();
  }

  async getContentAsText(response) {
    const fetchResponse: Response = response;
    if (fetchResponse.status === 204) {
      //  No content
      return undefined;
    }

    return await response.text();
  }

  async getBody(response) {
    const fetchResponse: Response = response;
    if (fetchResponse.status === 204) {
      //  No content
      return undefined;
    }

    return response.json();
  }

  getHeaders(response: any): Record<string, string> {
    const fetchResponse: Response = response;
    const headers: Record<string, string> = {};

    fetchResponse.headers.forEach((value, key, _parent) => {
      headers[key] = value;
    });

    return headers;
  }

  private async processFetchResponse(response: any) {
    const fetchResponse: Response = response;
    if (fetchResponse.status < 200 || fetchResponse.status > 304) {
      let message;

      try {
        const json = await fetchResponse.json();
        if (json?.message) {
          message = json.message;
        }
        console.log(json);
      }
      catch (err) {
        console.error(err);
      }

      if (!message) {
        message = `Request failed with status code ${fetchResponse.status}`;
      }

      throw new Error(message);
    }

    return fetchResponse;
  }
}
