import { IFilesystemItemResponseDTO } from '../interfaces/dto/filesystem-item-response-dto.interface';
import { IStorageConnectionCredentials } from '../interfaces/storage-connection-credentials.interface';
import { IStorageDecodedCredentials } from '../interfaces/storage-decoded-credentials.interface';
import { Base64Utils } from '../utils/base64-utils';
import { FgBaseAPI } from './base-api';

export class FgStorageFilesAPI extends FgBaseAPI {
  dbCredentials: IStorageConnectionCredentials;
  decodedCredentials: IStorageDecodedCredentials;

  constructor(param) {
    super(param);
    this.dbCredentials = param.dbCredentials;
    this.decodedCredentials = param.decodedCredentials;
  }

  get databaseUuid(): string {
    return this.dbCredentials.databaseUuid;
  }

  get projectId(): string {
    return this.decodedCredentials.projectId;
  }

  protected _afterLoad() {
    this.baseUrl += 'api/storage/files/';
  }

  async disconnect() {
    await this._delete(`${this.databaseUuid}`);
  }

  async uploadString(path: string, content: string) {
    await this.uploadFile(path, content);
  }

  async uploadFile(path: string, fileContent: string | Blob) {
    let fileName: string;

    const idx = (path || '').lastIndexOf('/');

    if (idx !== -1) {
      fileName = path.substring(idx + 1);
    }
    else {
      fileName = path;
    }

    if (!fileName) {
      throw new Error('Filename cannot be empty.');
    }

    await this._upload(this.baseUrl.replace('/api/', '/files/'), `content/${this.databaseUuid}/${path}`, null, this.projectId, fileContent, fileName);
  }

  async getFileId(path: string): Promise<number> {
    const { id } = await this._get(`id/${this.databaseUuid}/${path}`);
    return id;
  }

  getDownloadUrl(path: string) {
    const headers = this._createHeaders();
    const base64Headers = Base64Utils.stringToBase64(JSON.stringify(headers));
    return `${this.baseUrl}content/${this.databaseUuid}/${path}?headers=${base64Headers}&download=true`;
  }

  getDownloadByIdUrl(id) {
    const headers = this._createHeaders();
    const base64Headers = Base64Utils.stringToBase64(JSON.stringify(headers));
    return `${this.baseUrl}download/${this.databaseUuid}/${id}?headers=${base64Headers}&download=true`;
  }

  async download(path: string) {
    const downloadURI = (uri: string, name?: string) => {
      const link = document.createElement('a');
      if (name) {
        link.setAttribute('download', name);
      }
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
    };

    downloadURI(this.getDownloadUrl(path));
  }

  async downloadById(id) {
    const downloadURI = (uri: string, name?: string) => {
      const link = document.createElement('a');
      if (name) {
        link.setAttribute('download', name);
      }
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
    };

    downloadURI(this.getDownloadByIdUrl(id));
  }

  async downloadAsTextById(id, filePassword?: string) {
    const response = await this._getString(this.applyPasswordToUrl(`download/${this.databaseUuid}/${id}`, filePassword));
    return response;
  }

  async downloadAsJsonById(id, filePassword?: string) {
    const response = await this._get(this.applyPasswordToUrl(`download/${this.databaseUuid}/${id}`, filePassword));
    return response;
  }

  async downloadAsBlobById(id, filePassword?: string): Promise<Blob> {
    const response = await this._getBlob(this.applyPasswordToUrl(`download/${this.databaseUuid}/${id}`, filePassword));
    return response;
  }

  async downloadAsText(path, filePassword?: string): Promise<string> {
    const response = await this._getString(this.applyPasswordToUrl(`content/${this.databaseUuid}/${path}`, filePassword));
    return response;
  }

  async downloadAsJson(path, filePassword?: string): Promise<any> {
    const response = await this._get(this.applyPasswordToUrl(`content/${this.databaseUuid}/${path}`, filePassword));
    return response;
  }

  async downloadAsBlob(path, filePassword?: string): Promise<Blob> {
    //  TODO  downloadAsBlob Fix this
    const fileId = await this.getFileId(path);
    const response = await this.downloadAsBlobById(fileId, filePassword);
    return response;
  }

  async find(path): Promise<IFilesystemItemResponseDTO[]> {
    const { values } = await this._post(`${this.databaseUuid}/find`, { path });
    return values;
  }

  async exists(path): Promise<boolean> {
    const { exists } = await this._post(`${this.databaseUuid}/exists`, { path });
    return exists;
  }

  async deleteFile(path, filePassword?: string): Promise<number> {
    const { deleted } = await this._post(`${this.databaseUuid}/del`, { path, filePassword });
    return deleted;
  }

  protected _createHeaders(extraHeaders?: Record<string, string> | null): Record<string, string> {
    const headers = super._createHeaders(extraHeaders);

    if (this.dbCredentials) {
      headers['x-database-session'] = this.dbCredentials.token;
    }

    return headers;
  }

  private applyPasswordToUrl(url: string, filePassword?: string) {
    if (!filePassword) {
      return url;
    }

    return `${url}?filePassword=${filePassword}`;
  }
}
