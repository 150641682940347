import { StorageConnectionType } from '../enum/storage-connection-type';
import { IStorageConnectionCredentials } from '../interfaces/storage-connection-credentials.interface';
import { FgBaseAPI } from './base-api';

export class FgStorageAPI extends FgBaseAPI {
  protected _afterLoad() {
    this.baseUrl += 'api/storage/';
  }

  connectToDrive(driveUuid: string): Promise<IStorageConnectionCredentials> {
    return this.connect(driveUuid, 'files', '', '');
  }

  async connect(dbUuid: string, type: StorageConnectionType, userId: string, password: string) {
    const dto = { id: userId, pwd: password };

    const result = await this._post(`${type}/${dbUuid}`, dto)

    return {
      databaseUuid: dbUuid,
      token: result.token,
    } as IStorageConnectionCredentials;
  }
}
