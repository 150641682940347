import { Nexus } from './fg-api';
import { IFgApiInitializationOptions } from './interfaces/fg-api-initialization-options.interface';
import { initialize } from './legacy/fn-initialize';
import { initializeWithToken } from './legacy/fn-initialize-with-token';

export class NexusFactory {
  static initialize(key: string, secret: string, opts?: Partial<IFgApiInitializationOptions>): Promise<Nexus> {
    return initialize(key, secret, opts);
  }

  static initializeWithToken(keySecretToken: string, credentialSessionToken?: string, opts?: Partial<IFgApiInitializationOptions>): Promise<Nexus> {
    return initializeWithToken(keySecretToken, credentialSessionToken, opts);
  }
}