import { FetchRequestDriver } from '../drivers/fetch-request-driver';
import { Nexus } from '../fg-api';
import { IFgApiInitializationOptions } from '../interfaces/fg-api-initialization-options.interface';

export async function initializeWithToken(keySecretToken: string, credentialSessionToken?: string, opts?: Partial<IFgApiInitializationOptions>) {
  const initialOptions = { keySecretToken, credentialSessionToken, environment: 'production' };
  const finalOptions: any = Object.assign({}, initialOptions, opts || {});

  finalOptions.requestDriver = finalOptions.requestDriver || new FetchRequestDriver();

  const api = new Nexus(finalOptions);
  
  if (!credentialSessionToken) {
    await api.login();
  }

  return api;
}