import { IKeySecret } from '../interfaces';
import { Base64Utils } from '../utils/base64-utils';
import { BaseSessionManager } from './base-session-manager';

export class CredentialSessionManager extends BaseSessionManager {
  loadCredential() {
    let token = this._getContent();
    if (!token || token === '') {
      return null;
    }

    const isKeySecret = token.startsWith('*');
    if (isKeySecret) {
      token = token.substring(1);
    }

    return { token, isKS: isKeySecret };
  }

  saveCredential({ key, secret }: IKeySecret) {
    const token = this._makeTokenFromKS({ key, secret });
    this.saveContent(token);
  }

  saveCredentialToken(token: string) {
    this.saveContent(token);
  }

  getKeySecretFromToken(token: string) {
    const [key, secret] = Base64Utils.base64ToString(token).split(':');
    return { key, secret };
  }

  private _makeTokenFromKS({ key, secret }: IKeySecret) {
    const basicAuth = `${key}:${secret}`;
    const token = Base64Utils.stringToBase64(basicAuth);
    return `*${token}`;
  }
}
