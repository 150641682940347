import { IStorage } from '../interfaces/storage.interface';

export class BrowserStorage implements IStorage {
  save(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  delete(key: string) {
    localStorage.removeItem(key);
  }

  read(key: string) {
    const result = localStorage.getItem(key);
    return result;
  }
}
