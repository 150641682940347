import { IGetBoardDTO } from '../interfaces/dto/get-board-dto.interface';
import { ILeaderboardListItemDTO } from '../interfaces/dto/leaderboard-list-item-dto.interface';
import { ILeaderboardPartialTable } from '../interfaces/dto/leaderboard-partial-table.interface';
import { FgBaseAPI } from './base-api';

export class FgLeaderboardAPI extends FgBaseAPI {
  protected _afterLoad() {
    this.baseUrl += 'api/leaderboards/';
  }

  getList(): Promise<ILeaderboardListItemDTO[]> {
    return this.listBoards(false);
  }

  getListFilled(): Promise<ILeaderboardPartialTable[]> {
    return this.listBoards(true);
  }

  getBoard(id: number): Promise<IGetBoardDTO> {
    return this._get(`board/${id}`);
  }

  /**
   * Uploads a new score.
   * @param {number} id Board ID.
   * @param {number} score Score.
   * @param {string} [name] Player name (optional).
   * @param {string} [matchId] Match ID (optional).
   * @param {string} [refId] Reference ID (optional).
   * @returns {Promise<void>}
   */
  addScore(id: number, score: number, name: string | null = null, matchId: string | null = null, refId: string | null = null): Promise<void> {
    return this._post(`board/${id}`, { id, score, name, matchId, refId });
  }

  /**
   * Uploads a new score.
   * @param {number} id Board ID.
   * @param {number} score Score.
   * @param {string} [name] Player name (optional).
   * @param {string} [matchId] Match ID (optional).
   * @param {string} [refId] Reference ID (optional).
   * @param {string} [secret] Secret for protection (optional).
   * @returns {Promise<void>}
   */
  addScoreWithProtection(id: number, score: number, secret: string, name: string | null = null, matchId: string | null = null, refId: string | null = null, userId: string | null = null): Promise<void> {
    const timestamp = Date.now();

    const hashComponents = [
      id.toString(),
      name ?? '',
      matchId ?? '',
      refId ?? '',
      score.toString(),
      timestamp.toString(),
      userId ?? '',
      secret
    ];

    const componentsString = hashComponents.join(':');

    const token = this.encryptionDriver.getSha256HashBase64(componentsString);

    return this._post(`board/${id}`, { id, score, name, matchId, refId, timestamp, token });
  }

  private listBoards(fill: boolean) {
    if (!fill) {
      return this._get('board');
    }
    else {
      return this._get('board', { fill: true });
    }
  }
}