import { IEncryptionDriver } from '../../interfaces';
import { ICredentialObject } from '../../interfaces/credential-object.interface';
import { IRequestDriver } from '../../interfaces/request-driver.interface';
import { IStorageConnectionCredentials } from '../../interfaces/storage-connection-credentials.interface';
import { IStorageDecodedCredentials } from '../../interfaces/storage-decoded-credentials.interface';
import { Base64Utils } from '../../utils/base64-utils';
import { BaseAPIConstructorOpts } from '../base/base-api-constructor-opts';
import { BaseAPIConstructorParameters } from '../base/base-api-constructor-params';

export class FgStorageConnectedAPIConstructorParameters extends BaseAPIConstructorParameters {
  dbCredentials: IStorageConnectionCredentials;
  decodedCredentials: IStorageDecodedCredentials;

  constructor(
    dbCredentials: IStorageConnectionCredentials,
    driver: IRequestDriver,
    encryptionDriver: IEncryptionDriver,
    baseUrl?: string,
    opts?: BaseAPIConstructorOpts,
    credentialObject?: ICredentialObject) {
    super(driver, encryptionDriver, baseUrl, opts, credentialObject);
    this.dbCredentials = dbCredentials;
    this.decodedCredentials = JSON.parse(this._decodeToken(dbCredentials.token));
  }

  private _decodeToken(token): string {
    const b64 = token.split('.')[1];
    return Base64Utils.base64ToString(b64);
  }
}
