import { FetchRequestDriver } from '../drivers/fetch-request-driver';
import { Nexus } from '../fg-api';
import { IFgApiInitializationOptions } from '../interfaces/fg-api-initialization-options.interface';

/**
 * Initialize the FgAPI object.
 * @param {string} key Key
 * @param {string} secret Secret
 * @param {*} [opts] Extra settings
 * @param {string} [opts.environment] Environment (default: production). Values: production, development, rapidapi.
 * @param {*} [opts.requestDriver] Request driver (default: FetchRequestDriver)
 * @param {number} [opts.timeout] Timeout (default: 30)
 * @returns 
 */
export async function initialize(key: string, secret: string, opts?: Partial<IFgApiInitializationOptions>): Promise<Nexus> {
  const initialOptions = { key, secret, environment: 'production' };
  const finalOptions: any = Object.assign({}, initialOptions, opts || {});

  finalOptions.requestDriver = finalOptions.requestDriver || new FetchRequestDriver();

  const api = new Nexus(finalOptions);
  await api.login();
  return api;
}
