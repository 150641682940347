import { IStorage } from '../interfaces/storage.interface';

export class MemoryStorage implements IStorage {
  private data: Record<string, string> = {};

  save(key, value) {
    this.data[key] = value;
  }

  delete(key) {
    delete this.data[key];
  }

  read(key) {
    const result = this.data[key];
    return result;
  }
}
